<template>
  <div id="app" :class="{'app-oce-container': fromOce == 1}">
    <router-view />
  </div>
</template>
<script>
import {debounce} from '@/utils';
import {setLocalStorage, getLocalStorage} from '@/utils/index';
import firstMixin from '@/mixins/firstMixin';

export default {
  name: 'App',
  mixins: [firstMixin],
  data() {
    return {
      fromOce: 0,
    };
  },
  computed: {
    isOce() {
      return this.$store.state.user.isOce || getLocalStorage('isOce');
    },
  },
  watch: {
    isOce(nVal, oVal) {
      this.$set(this, 'fromOce', parseInt(nVal));
    },
  },
  mounted() {
    window.localStorage.removeItem('login-from');
    // this.$set(this, 'fromOce', this.isOce);
    // this.resizeHandler();
    // this.$nextTick(() => {
    //   window.addEventListener('resize', this.resizeHandler);
    // });
    // let userName = getLocalStorage('user_name');
    // if (userName) {
    //   this.$store.dispatch('setWaterMarket');
    // }
  },
  methods: {
    resizeHandler() {
      let self = this;
      setTimeout(() => {
        let navigator = window.navigator;
        // if (navigator.userAgent.includes('iPad')) {
        const isOce = getLocalStorage('login-from');
        if (window.orientation === 180 || window.orientation === 0) {
          // 竖屏
          if (isOce === 'oce') {
            self.$store.dispatch('setOCE', 1);
          }
        }
        if (window.orientation === 90 || window.orientation === -90) {
          // 横屏
          if (isOce === 'oce') {
            self.$store.dispatch('setOCE', 0);
          }
        }
        // }
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
